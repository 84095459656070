
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import dayjs from "dayjs";
const plusTemplate = namespace("plusTemplate");

@Component({
  computed: {
    dayjs() {
      return dayjs
    }
  }
})
export default class TestItemEdit extends Vue {
  @plusTemplate.Action getPlusTemplateDetail;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  get breadData() {
    return [
      { name: "plus卡模板", path: "/plusCard/template/index" },
      {
        name: "查看"
      }
    ];
  }
  get plusTemplateCode() {
    return this.$route.params.plusTemplateCode;
  }

  created() {
    this.init();
  }
  plusTemplateDetail: any = {};

  init() {
    // 获取详情
    if(this.plusTemplateCode){
      console.log("plusTemplateCode = " +this.plusTemplateCode)
      this.getPlusTemplateDetail({plusTemplateCode: this.plusTemplateCode}).then(res => {
        console.log(res)
        this.plusTemplateDetail = res.data;
      })
    }
  }

  cardType(val) {
    if(val === 1){
      return '会员卡'
    }else if(val == 2){
      return '超级会员卡'
    }else{
      return '-'
    }
  }

  benefitType(val) {
    if(val === 1){
      return '首次免单权益'
    }else if(val == 2){
      return '优惠券'
    }else if(val == 3){
      return '会员专享价'
    }else if(val == 4){
      return '超值免费服务'
    }else{
      return '-'
    }
  }

  distributionMethod(val) {
    if(val === 0) {
      return '无'
    }else if(val === 1){
      return '自动发放'
    }else if(val == 2){
      return '手动领取'
    }else{
      return '-'
    }
  }
  freeOrderLimit(val) {
    if(val === 0){
      return '无'
    }else if(val === 1){
      return '无门槛限制'
    }else if(val == 2){
      return '服务项限制'
    }else{
      return '-'
    }
  }

}
